import React from "react";

export default function Herosection() {
  return (
    <div>
      <div className="herosection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 align-self-center text-center">
              <h1>Launching Soon..</h1>
              <h6>
                <b> An Elevated Living Experience </b>
              </h6>
              <p>
                <b> Birla Ojasvi</b>, Rajarajeshwari Nagar is located where your
                heart is. Being true to its name, the project promises the
                beginning of a life and lifestyle, unlike any other, in the most
                premium land parcel in the city. Specially with a stunning
                forest view at the front. Birla Ojasvi is the new paradigm of
                ultra high-rise living which features <b> 3 unique towers </b>
                with 33 floors along with <b>2, 3 bedrooms</b> & select
                <b> row villas.</b> And, it offers thoughtfully curated
                amenities designed for an elevated lifestyle.
              </p>
              <button
                type="button"
                class="btn mt-2"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Enquire Now
              </button>

              <div
                class="modal fade text-left"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered  modal-lg"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Contact form
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <form
                        name="contact"
                        method="post"
                        data-netlify="true"
                        onSubmit="submit"
                        data-netlify-honeypot="bot-field"
                      >
                        <input type="hidden" name="form-name" value="contact" />
                        <div class="row">
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Name"
                              name="name"
                            />
                          </div>
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Phone Number"
                              name="phonenumber"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div class="col">
                            <input
                              required
                              type="text"
                              class="form-control"
                              placeholder="Email"
                              name="email"
                            />
                          </div>
                          <div class="col">
                            <fieldset>
                              <div>
                                <label>Need Brochure</label>
                                <br class="mobile-break"></br>
                                <input
                                  type="radio"
                                  class=" ml-0 ml-md-3 mr-1"
                                  name="brochure"
                                  value="Yes"
                                  id="y"
                                />
                                <label htmlFor="y">Yes</label>
                                <input
                                  type="radio"
                                  class="radio ml-3 mr-1"
                                  name="brochure"
                                  value="No"
                                  id="n"
                                />
                                <label htmlFor="no">No</label>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col">
                            <fieldset>
                              <div>
                                <label>Your Preference </label>
                                <br class="mobile-break"></br>
                                <input
                                  type="radio"
                                  class="ml-0 ml-md-3 mr-1"
                                  name="bhk"
                                  value="1BHK"
                                  id="1BHK"
                                />
                                <label htmlFor="1BHK">1 BHK</label>
                                <input
                                  type="radio"
                                  class="radio ml-3 mr-1"
                                  name="bhk"
                                  value="2BHK"
                                  id="2BHK"
                                />
                                <label htmlFor="2BHK">2 BHK</label>
                                <input
                                  type="radio"
                                  class="radio ml-3 mr-1"
                                  name="bhk"
                                  value="3BHK"
                                  id="3BHK"
                                />
                                <label htmlFor="3BHK">3 BHK</label>
                                <input
                                  type="radio"
                                  class="radio ml-3 mr-1"
                                  name="bhk"
                                  value="Row villas"
                                  id="Rowvillas"
                                />
                                <label htmlFor="Rowvillas">Row villas</label>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div class="col">
                            <input
                              type="text"
                              required
                              class="form-control"
                              placeholder="Message"
                              name="message"
                            />
                          </div>
                        </div>
                        {/* 
                        <div className="row">
                          <div className="col-12">
                            <div
                              style={{ fontSize: 12 }}
                              className="text-center"
                            >
                              *Phase - 1 Sold Out,{" "}
                              <b>Hurry up for Phase - 2 (NEW LAUNCH)</b>
                            </div>
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-12 text-center">
                            <button
                              type="submit"
                              className="btn btn-primary mt-4"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <img className="img-fluid" src="./images/banner_img.jpg" alt="" />
              <br />
              <br />
              <small>*illustration purpose only</small>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="disclimer">
                Disclaimer The Project is being Developed by Birla Estates pvt.
                ltd.. The Project is registered as "Birla Ojasvi" with RERA ACK.
                No: ACK/KA/RERA/1251/310/PR/081223/007659, available at
                website:http://rera.karnataka.gov.in. The information is
                presented as general information and no warranty is expressly or
                impliedly given that the completed development will comply in
                any degree with such artist’s impression or anticipated
                appearance.Recipients are advised to apprise themselves of the
                necessary and relevant information of the project(s)/offer(s)
                prior to making any purchase decisions. The Sale is subject to
                terms of Application Form and Agreement for Sale. All
                specifications of the unit shall be as per the final agreement
                between the Parties. *The prices mentioned are an indicative
                Agreement Value. Stamp Duty Registration, GST and Other Charges
                over and above the Agreement Value. The amenities mentioned here
                are a part of Birla Ojasvi and proposed future development. This
                is an initiative by Smita an Individual Channel Partner RERA
                Regn. No. PRM/KA/RERA/1251/310/AG/220521/002906 to provide
                information about Birla Ojasvi is not to be construed as an
                official site of Birla Ojasvi. Distance mentioned is as per
                google map. Drive time refers to the time taken to travel by a
                car based on normal traffic conditions during non-peak hours as
                per Google maps may vary according to the current traffic
                conditions. Information provided is based on Online sources, the
                developer does not provide any guarantee on the same.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
