import "./App.css";
import Header from "./Header";
import Herosection from "./Herosection";
import Model_popup from "./Modal_popup";

function App() {
  return (
    <div>
      <Header />
      <Herosection />
      <Model_popup />
    </div>
  );
}

export default App;
