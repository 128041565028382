import React from "react";

export default function Model_popup() {
  return (
    <div>
      {/* <div className="model_pageload">
        <div
          class="modal fade "
          id="pageload_model"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center">
                <div className="phase_two">
                  <h1>
                    Hurry up! <br />
                  </h1>
                  <h3> Pre bookings open now..</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
