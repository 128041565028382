import React from "react";

export default function Header() {
  return (
    <div className="header mt-2">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 col-md-6 align-self-center">
            <img
              src="./images/logo.png"
              className="img-fluid birla_estate"
              alt=""
            />
          </div>
          <div className="col-6 text-right align-self-center col-md-6">
            <img
              src="./images/birla_aditya.jpg"
              className="img-fluid aditya_birla"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
